<template>
  <b-card
      class="pricefixedruleonlinespecial-edit-wrapper"
  >
    <b-row>
      <b-col md="3" class="ml-2">
        <div class="inner-card-title font-large-1">定价规则3-特殊品类</div>
      </b-col>
      <b-col md="0">
        <b-button
            variant="primary"
            @click="repeateAgain"
            class="mt-0"
        >
          <feather-icon
              icon="PlusIcon"
          />
          <span>添加</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- form -->
    <b-form id="pricefixedruleonlinespecialForm" class="edit-form mt-2">
      <b-row v-for="(item,index) in pricefixedruleonlinespecial"
             :id="item.rule_id"
             :key="item.rule_id"
             ref="row"
             class="mt-1"
      >
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="商品分类"
              label-for="car_no"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                :options="getCodeOptions('categorys',true).category3"
                :clearable="true"
                class="select-size-sm"
                v-model="item.categoryModel"
                placeholder="请选择商品分类"
                @input="changeSelect('category_three_id',item,$event)"
            />
          </b-form-group>
        </b-col>

        <b-col md="8">
          <b-row>
            <b-col md="3">
              <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label="区间单位"
                  label-for="car_no"
                  label-size="sm"
                  class="mb-1"
              >
                <v-select
                    :options="getCodeOptions('unit_type')"
                    :clearable="true"
                    class="select-size-sm"
                    v-model="item.unitModel"
                    placeholder="请选择区间单位"
                    @input="changeSelect('unit_type',item,$event)"
                />
              </b-form-group>
            </b-col>

            <b-col md="5">
              <b-row>
                <b-col md="5">
                  <b-form-input
                      size="sm"
                      type="number"
                      v-model="item.unit_min"
                  />
                </b-col>
                ~
                <b-col md="5">
                  <b-form-input
                      size="sm"
                      type="number"
                      v-model="item.unit_max"
                  />
                </b-col>
              </b-row>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label="商品单位"
                  label-for="car_no"
                  label-size="sm"
                  class="mb-1"
              >
                <v-select
                    :options="[getCode('product_unit',2000001231),getCode('product_unit',2000001345)]"
                    :clearable="true"
                    class="select-size-sm"
                    v-model="item.productModel"
                    placeholder="请选择商品单位"
                    @input="changeSelect('product_unit',item,$event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="毛利率（%）"
              label-for="car_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                type="number"
                v-model="item.gross_profit_rate"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="毛利额（元）"
              label-for="car_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                type="number"
                v-model="item.gross_profit_amount"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-button
              variant="success"
              class="mr-1"
              @click="save(item)"
          >
            <feather-icon
                icon="SaveIcon"
                class="mr-25"
            />
            <span>保存</span>
          </b-button>

          <b-button
              variant="danger"
              class="mr-1"
              @click="removeItem(item.rule_id,index)"
          >
            <feather-icon
                icon="XIcon"
                class="mr-25"
            />
            <span>删除</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import pricefixedruleonlinespecialStore from './pricefixedruleonlinespecialStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty,
  isEmptys
} from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  methods: {
    repeateAgain() {
      this.pricefixedruleonlinespecial.push({state: 0})
    },
  },
  data() {
    return {
      id: ref(0),
      pricefixedruleonlinespecial: [],
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('pricefixedruleonlinespecial')) store.registerModule('pricefixedruleonlinespecial', pricefixedruleonlinespecialStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('pricefixedruleonlinespecial')) store.unregisterModule('pricefixedruleonlinespecial')
    })

    const search = function () {
      store.dispatch('pricefixedruleonlinespecial/search').then(res => {
        const data = res.data.data.list
        for (let i = 0; i < data.length; i++) {
          data[i].categoryModel = getCodeOptions('categorys', true).category3.find(item => String(item.value) == String(data[i].category_three_id)) || ""
          data[i].unitModel = getCode('unit_type', data[i].unit_type)
          data[i].productModel = getCode('product_unit', data[i].product_unit)
          this.pricefixedruleonlinespecial.push(data[i])
        }
      })
    }

    const save = function (params) {
      if (isEmpty(params.category_three_id)) {
        toast.error("请选择商品分类!")
        return false
      }
      if (isEmpty(params.unit_type)) {
        toast.error("请选择区间单位!")
        return false
      }
      if (isEmptys(params.unit_min)||isEmptys(params.unit_max)) {
        toast.error("请填写区间值!")
        return false
      }
      if (parseFloat(params.unit_max)<parseFloat(params.unit_min)){
        toast.error("区间错误!")
        return false
      }
      if (parseFloat(params.unit_max)<0||parseFloat(params.unit_min)<0){
        toast.error("区间错误!")
        return false
      }
      if (isEmpty(params.product_unit)) {
        toast.error("请选择商品单位!")
        return false
      }
      if (isEmptys(params.gross_profit_rate)) {
        toast.error("请填写毛利率!")
        return false
      }
      if (isEmptys(params.gross_profit_amount)) {
        toast.error("请填写毛利额!")
        return false
      }
      if (params.gross_profit_rate<0){
        toast.error("毛利率不能为负!")
        return false
      }
      if (params.gross_profit_amount<0){
        toast.error("毛利额不能为负!")
        return false
      }
      store.dispatch('pricefixedruleonlinespecial/save', params).then(res => {
        if (res.data.code==0){
          toast.success('数据已保存!')
          params.rule_id = res.data.data.rule_id
        }else {
          toast.error(res.data.data)
        }

      })
    }

    const changeSelect = function (key, params, event) {
      if (event != null) {
        params[key] = event.value
      } else {
        params[key] = null
      }
    }

    const removeItem = function (id, index) {
      if (confirm("确定删除吗?")) {
        if (isEmpty(id)) {
          this.pricefixedruleonlinespecial.splice(index, 1)
        } else {
          store.dispatch('pricefixedruleonlinespecial/state', {id: id, state: 0}).then(res => {
            toast.success("删除成功!")
            this.pricefixedruleonlinespecial.splice(index, 1)
          })
        }
      }
    }

    return {
      save,
      removeItem,
      changeSelect,
      search,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      isEmptys,
    }
  },
  created() {
    this.search()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
